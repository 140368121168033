import Repo from './_repo.js'

let oauthToken = null
let displayMode = null

export default class extends Repo {
  static _restUrl(path, params) {
    const url = `https://api.trello.com/1${path}`
    return GLib.url.appendParams(url, params)
  }

  static restFetch(path, method, params) {
    if (!oauthToken) {
      return Promise.reject("Unauthorized operation")
    }
    return fetch(this._restUrl(path, params), {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Authorization': `OAuth oauth_consumer_key="${$tpu.globals.trelloKey}", oauth_token="${oauthToken}"`
      }
    })
    .then(response => {
      switch (response.status) {
        case 200:
          return response.text();
        case 401:
          // Give the user a chance to re-authorize.
          const t = TrelloPowerUp.iframe()
          t.remove("member", "private", $tpu.keys.mbrAuthToken())
          throw "Reauthorization required. Please close and relaunch the Power-Up."
      }
      throw `Invalid response: ${response.status} ${response.statusText}`
    })
    .then(text => { return JSON.parse(text) })
  }

  static set(t, scope, visibility, key, value) {
    if (this.isInTrello()) {
      return t.set(scope, visibility, key, value)
    }

    return new Promise((resolve, reject) => {
      const data = this._getStorage(scope, visibility)
      if (value) {
        data[scope][visibility][key] = value
      } else {
        Object.assign(data[scope][visibility], key)
      }
      localStorage.setItem('_tpu_data', JSON.stringify(data))
      resolve()
    })
  }

  static get(t, scope, visibility, key, defaultValue) {
    if (this.isInTrello()) {
      return t.get(scope, visibility, key, defaultValue)
    }

    return new Promise((resolve, reject) => {
      const data = this._getStorage(scope, visibility)[scope][visibility]
      resolve(key ? (data[key] || defaultValue) : data)
    })
  }

  static remove(t, scope, visibility, key) {
    if (this.isInTrello()) {
      return t.remove(scope, visibility, key)
    }

    return new Promise((resolve, reject) => {
      const data = this._getStorage(scope, visibility)
      delete data[scope][visibility][key]
      localStorage.setItem('_tpu_data', JSON.stringify(data))
      resolve()
    })
  }

  static _getStorage(scope, visibility) {
    const data = this._getAll() || {}
    data[scope] ||= {}
    data[scope][visibility] ||= {}
    return data
  }

  static _getAll() {
    if (this.isInTrello()) {
      return this.trelloFrame._getAll();
    }

    return JSON.parse(localStorage.getItem('_tpu_data'))
  }

  static async preloadOauthToken(t) {
    if (!oauthToken) {
      if (this.isInTrello()) {
        const data = await this.get(t, "member", "private")
        oauthToken = data[$tpu.keys.mbrAuthToken()]
      } else {
        oauthToken = process.env.DEV_OAUTH_TOKEN
      }
    }

    return oauthToken
  }

  static async setDisplayMode(value) {
    displayMode = value
  }

  static httpHeaders(_url, _method) {
    return {
      "App-Trello-Token": oauthToken,
      "App-Display-Mode": displayMode
    }
  }

  static async getMemberBoardData(t) {
    const board = await $tpu.urls.preloadBoard(t)
    const data = await this.get(t, "member", "private", $tpu.keys.mbrBoardId(board.id)) || {}

    // Enable unless explicitly disabled
    if (data.autoLaunch !== false) {
      data.autoLaunch = true
    }
    return data
  }

  // TODO: Deprecate in favour of updateMemberBoardData()
  // static async setMemberBoardData(t, data) {
  //   const board = await $tpu.urls.preloadBoard(t)
  //   return await this.set(t, "member", "private", $tpu.keys.mbrBoardId(board.id), data)
  // }

  static async freshUpdateMemberBoardData(t, updater) {
    const [board, data] = await Promise.all([
      $tpu.urls.preloadBoard(t),
      $tpu.r.data.getMemberBoardData(t)
    ])
    updater(data)

    return await this.set(t, "member", "private", $tpu.keys.mbrBoardId(board.id), data)
  }
}
